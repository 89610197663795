<template>
  <div class="section-aboute" id="aboute">
    <div class="container">
      <ui-title class="section-aboute__title" text="О компании" line/>
      <div class="section-aboute__content-wrap">    
        <div class="section-aboute__info">
          <ui-title class="section-aboute__info-title" text="Контактная информация"/>
          <div class="section-aboute__desc">Телефон: +7(495) 1080623</div>
          <div class="section-aboute__desc">Email: info@xset.ru</div>
          <ui-title class="section-aboute__info-title" text="Юридическая информация"/>
          <div class="section-aboute__desc">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИКСЕТ ТЕХНОЛОГИИ"</div>
          <div class="section-aboute__desc">Юридический адрес: г. Комсомольск-на-Амуре, Ленина 91/2 кв. 73</div>
          <div class="section-aboute__desc">ИНН: 2703104656</div>
          <div class="section-aboute__desc">ОГРН: 1222700005230</div>
          <div class="section-aboute__desc">ОКВЭД: 62.09 - Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая применяемая система налогообложения</div>
        </div>
        <!--img class="section-aboute__image" src="@/assets/images/aboute.jpg" /-->
      </div>
    </div>
  </div>
</template>

<script>

import UiTitle from '@/components/ui/UiTitle'
//import UiButton from '@/components/ui/UiButton'

export default {
  components: {
    UiTitle,
  //  UiButton,
  },
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-aboute__desc {
  /* Разрешить выделение текста */
  user-select: text;
}

.section-aboute__desc::selection {
  background: #b0b0b0; /* Цвет фона выделения (серый) */
  color: #000;        /* Цвет текста выделения (черный) */
}

.section-aboute {
  display: flex;
  justify-content: center;
  padding: 40px 0;

  &__title {
    margin-bottom: 24px;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: fit-content;
    gap: 11%;
    margin-bottom: 64px;

    @include breakpoint(desktop) {
      gap: 20px;
    }

    @include breakpoint(phablet) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      @include breakpoint(phablet) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }

    @include breakpoint(phablet) {
      align-items: center;
    }
  }
  
  &__desc {
    margin-bottom: 32px;

    @include breakpoint(phablet) {
      text-align: center;
    }
  }

  &__image {
    @include breakpoint(laptop) {
      width: 431px;
      height: 431px;
    }

    @include breakpoint(tablet) {
      width: 300px;
      height: 300px;
    }
  }
}
</style>
