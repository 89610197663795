<template>
  <form 
    class="form"
    method="POST"
    action=""
    ref="form"
    :style="dynamicStyles"
    @submit.prevent=""
  >
    <div 
      class="form__block-done"
      v-if="isDone"
    >
      Информация успешно отправлена, с Вами обязательно свяжутся!
      <IconSmile class="form__block-done__smile"/>
    </div>
    <template v-else>
      <span class="form-prompt">Наши контакты</span>
      <span class="form-prompt"><a href="https://t.me/kova_di"><img width="32" alt="Telegram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/32px-Telegram_logo.svg.png?20220101141644"></a></span>
      <span class="form-prompt">email: info@xset.ru</span>
      <span class="form-prompt">телефон: +7(495) 1080623</span>
    </template>
  </form>
</template>



   <!--

    <span class="form-prompt">ИНН: 2703104656</span>
    <span class="form-prompt"><center>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИКСЕТ ТЕХНОЛОГИИ"</center></span>
    <span class="form-prompt"><center>Юридический адрес: 681000 г. Комсомольск-на-Амуре, Ленина 91/2 кв. 73</center></span >

      <span class="form-prompt">Ваше имя</span>
      <input 
        type="text" 
        class="form-input"
        placeholder="Имя"
        v-model="name"
      >
      <span class="form-prompt">Ваш номер телефона</span>
      <input 
        type="tel" 
        class="form-input"
        placeholder="Телефон"
        maxlength="12"
        v-model="phone"
      >
      <template v-if="!simple">
        <span class="form-prompt">Вакансия</span>
        <input 
          type="text" 
          class="form-input"
          placeholder="Вакансия"
          v-model="vacancy"
        >
        <span class="form-prompt">Уровень</span>
        <input 
          type="text" 
          class="form-input"
          placeholder="Ваш уровень"
          v-model="level"
        >
      </template>
      <div class="form-agreement">
        <input 
          class="form-agreement-input" 
          type="checkbox" 
          name="agreement-modal" 
          id="agreement-modal"
          v-model="agreementValue"
        >
        <label 
          class="form-agreement-label" 
          for="agreement-modal"
        >Нажимая на кнопку “Отправить”, я <span>даю согласие</span> на обработку моих персональных данных</label>
      </div>
      <div class="section-contact__send" @click="sendForm">Отправить</div>
      -->
<script>
import IconSmile from '@/components/icons/IconSmile'
export default {
  components: {
    IconSmile,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agreementValue: false,
      name: '',
      phone: null,
      vacancy: '',
      level: '',
      isDone: false,
    }
  },
  computed: {
    dynamicStyles () {
      return {
        minHeight: this.simple ? '378px' : '550px',
      }
    }
  },
  methods: {
    sendForm () {
      if (this.agreementValue && this.phone) {
        const newData = {
          'name': this.name,
          'phone': this.phone,
          'vacancy': this.vacancy,
          'level': this.level,
        }
        console.log('data: ', newData)
        this.sendData(newData)
        this.isDone = true
      }
    },
    sendData(data) {
      this.axios.post('/send.php', data, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";
@import "../assets/scss/animations.scss";

.form-prompt {
  /* Разрешить выделение текста */
  user-select: text;
}
  .form-prompt::selection {
  background: #b0b0b0; /* Цвет фона выделения (серый) */
  color: #000;        /* Цвет текста выделения (черный) */
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1C3BBD;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 300px;
  animation: showModal .7s;

  &__block-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    animation: blockDone .7s;

    &__smile {
      width: 75px;
      height: 75px;
      margin-top: 20px;
    }
  }

  @include breakpoint(mobileonly) {
    width: 95%;
  }

  &-prompt {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  &-input {
    width: 100%;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    background-color: transparent;
    padding: 8px;
    border: 2px solid #FFFFFF;
    border-radius: 2px;
    margin-bottom: 16px;

    &::placeholder {
      color: #fff;
      opacity: .5;
    }
  }

  &-agreement {
    display: flex;
    align-items: center;
    gap: 13px;
    margin: 16px 0 40px;

    &-label {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.4px;
      color: #FFFFFF;

      span {
        opacity: .6;
      }
    }
  }
}
</style>
