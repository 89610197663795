<template>
  <div class="section-contact" id="contact">
    <div class="section-contact__title">
      <span>Оставьте свои данные</span>
      <span>и мы свяжемся с вами!</span>

    </div>
    <Form simple/>
  </div>
</template>

<script>
import Form from '../Form'
export default {
  components: {
    Form,
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/images/contact-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 0;
  
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    max-width: 430px;
    margin-bottom: 24px;

    @include breakpoint(phablet) {
      font-size: 28px;
    }
  }

  &__send {
    @include button-white-bordered;
  }
}
</style>
