<template>
<svg xmlns="http://www.w3.org/2000/svg" width="262" height="20" viewBox="0 0 262 20" fill="none">
<path d="M9.85176 0.0849609C11.8482 1.69025 13.5089 3.02169 14.8336 4.07929C16.177 5.1369 17.2872 6.01508 18.1642 6.71385L25.1332 1.61471C26.402 0.689305 27.82 0.226604 29.3874 0.226604H33.7815L21.3828 9.29176C23.5659 11.067 25.7862 12.8517 28.0439 14.6459C30.3203 16.44 32.578 18.2247 34.817 20H24.7973C22.6889 18.3191 20.935 16.931 19.5356 15.8357C18.1549 14.7214 17.0353 13.8149 16.177 13.1161L8.67627 18.6119C7.40748 19.5373 5.98943 20 4.4221 20H0L12.9584 10.5382C12.6972 10.3305 12.4546 10.1416 12.2307 9.97164C12.0255 9.80167 11.7643 9.59393 11.4471 9.34841C11.1299 9.08401 10.7101 8.75351 10.1876 8.35691C9.68384 7.94143 8.99347 7.3843 8.11651 6.68553C7.23955 5.98675 6.14802 5.11801 4.84192 4.07929C3.53581 3.04058 1.92184 1.75635 0 0.226604L9.85176 0.0849609Z" fill="white"/>
<path d="M50.8368 3.48445C50.1651 3.48445 49.5867 3.72052 49.1016 4.19266C48.6165 4.66481 48.3739 5.24082 48.3739 5.92071C48.3739 6.26065 48.4392 6.58171 48.5698 6.88388C48.7004 7.18605 48.8777 7.45045 49.1016 7.67708C49.3255 7.90371 49.5867 8.08312 49.8852 8.21532C50.1838 8.34752 50.501 8.41362 50.8368 8.41362H65.6425C66.4261 8.41362 67.1538 8.56471 67.8255 8.86688C68.4972 9.15017 69.0943 9.54677 69.6168 10.0567C70.1019 10.5477 70.4844 11.1048 70.7643 11.7281C71.1374 12.5213 71.324 13.3522 71.324 14.221C71.324 15.0142 71.1748 15.7602 70.8762 16.4589C70.5777 17.1577 70.1672 17.7715 69.6447 18.3003C69.1223 18.8291 68.5159 19.2446 67.8255 19.5468C67.1352 19.8489 66.3981 20 65.6145 20H40.8171L42.4684 18.3286C43.5693 17.2144 44.8661 16.6572 46.3588 16.6572H61.5002C62.172 16.6572 62.7504 16.4212 63.2355 15.949C63.7206 15.4769 63.9632 14.9009 63.9632 14.221C63.9632 13.881 63.8979 13.56 63.7673 13.2578C63.6367 12.9556 63.4594 12.6912 63.2355 12.4646C63.0116 12.238 62.7504 12.0586 62.4518 11.9264C62.1533 11.7942 61.8361 11.7281 61.5002 11.7281H46.6946C45.9109 11.7281 45.1833 11.5864 44.5115 11.3031C43.8398 11.001 43.2428 10.5949 42.7203 10.085C42.2352 9.59398 41.8527 9.03685 41.5728 8.41362C41.1996 7.62042 41.0131 6.78945 41.0131 5.92071C41.0131 5.12751 41.1623 4.38152 41.4609 3.68275C41.7594 2.98398 42.1699 2.37019 42.6923 1.84139C43.2148 1.31259 43.8212 0.8971 44.5115 0.594929C45.2019 0.292757 45.9389 0.141671 46.7226 0.141671H71.5199L69.8687 1.81306C69.3276 2.36075 68.7305 2.77623 68.0774 3.05952C67.443 3.3428 66.7433 3.48445 65.9783 3.48445H50.8368Z" fill="white"/>
<path d="M81.061 3.45612V11.3881C81.4715 10.9726 81.854 10.5855 82.2085 10.2267C82.563 9.84894 82.9362 9.52788 83.328 9.26348C83.7385 8.9802 84.177 8.76301 84.6435 8.61192C85.1286 8.44195 85.6977 8.35697 86.3507 8.35697H100.597V11.6997H81.061V16.5723H99.1692C99.8409 16.5723 100.419 16.6667 100.904 16.8555C101.408 17.0444 101.865 17.2994 102.276 17.6204C102.705 17.9226 103.106 18.2814 103.479 18.6969C103.853 19.0935 104.254 19.509 104.683 19.9434H73.6722V0.113342H104.683C104.254 0.547714 103.843 0.972643 103.451 1.38813C103.078 1.78473 102.677 2.14356 102.248 2.46462C101.837 2.76679 101.38 3.0123 100.876 3.20116C100.391 3.39002 99.8129 3.48445 99.1412 3.48445L81.061 3.45612Z" fill="white"/>
<path d="M125.529 3.45612V19.9717H118.14V3.45612H104.174C104.603 3.02175 105.004 2.60626 105.378 2.20966C105.769 1.79417 106.171 1.43534 106.581 1.13317C107.01 0.831001 107.467 0.59493 107.952 0.424958C108.456 0.2361 109.044 0.141671 109.716 0.141671H110.527H110.891H125.501H139.467C139.038 0.576042 138.627 1.00097 138.235 1.41646C137.862 1.81306 137.461 2.16245 137.032 2.46462C136.621 2.76679 136.164 3.0123 135.66 3.20116C135.175 3.37113 134.597 3.45612 133.925 3.45612H125.529Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M147.967 3.10937e-06L147.967 20L145.467 20L145.467 3.21865e-06L147.967 3.10937e-06Z" fill="white"/>
<path d="M153.967 4.86153V3.49435H163.512V4.86153H159.51V16.2216H157.969V4.86153H153.967Z" fill="white"/>
<path d="M168.31 16.4205C167.39 16.4205 166.597 16.2175 165.93 15.8115C165.267 15.4013 164.755 14.8296 164.395 14.0963C164.038 13.3588 163.86 12.5012 163.86 11.5235C163.86 10.5457 164.038 9.68397 164.395 8.93824C164.755 8.18835 165.256 7.60419 165.899 7.18575C166.545 6.76317 167.299 6.55187 168.161 6.55187C168.658 6.55187 169.149 6.63473 169.634 6.80045C170.118 6.96617 170.559 7.23547 170.957 7.60834C171.355 7.97706 171.672 8.46593 171.908 9.07495C172.144 9.68397 172.262 10.4339 172.262 11.3246V11.946H164.904V10.6783H170.771C170.771 10.1397 170.663 9.65912 170.448 9.23653C170.236 8.81395 169.934 8.48043 169.54 8.236C169.151 7.99156 168.691 7.86934 168.161 7.86934C167.577 7.86934 167.071 8.01435 166.644 8.30436C166.222 8.59022 165.897 8.96309 165.669 9.42297C165.441 9.88284 165.327 10.3759 165.327 10.902V11.7472C165.327 12.4681 165.451 13.0792 165.7 13.5805C165.952 14.0776 166.303 14.4567 166.75 14.7177C167.197 14.9746 167.717 15.103 168.31 15.103C168.695 15.103 169.043 15.0491 169.354 14.9414C169.669 14.8296 169.94 14.6638 170.168 14.4443C170.396 14.2205 170.572 13.943 170.696 13.6115L172.113 14.0093C171.964 14.4898 171.713 14.9124 171.361 15.277C171.009 15.6375 170.574 15.9192 170.056 16.1222C169.538 16.321 168.956 16.4205 168.31 16.4205Z" fill="white"/>
<path d="M178.371 16.4205C177.476 16.4205 176.706 16.2092 176.059 15.7866C175.413 15.364 174.916 14.7819 174.568 14.0403C174.22 13.2987 174.046 12.4515 174.046 11.4986C174.046 10.5291 174.224 9.67362 174.58 8.93202C174.941 8.18628 175.442 7.60419 176.084 7.18575C176.73 6.76317 177.484 6.55187 178.346 6.55187C179.017 6.55187 179.622 6.67616 180.161 6.92474C180.699 7.17332 181.141 7.52133 181.485 7.96878C181.828 8.41622 182.042 8.93824 182.125 9.53483H180.658C180.546 9.09981 180.298 8.71451 179.912 8.37893C179.531 8.03921 179.017 7.86934 178.371 7.86934C177.799 7.86934 177.298 8.01849 176.867 8.31679C176.44 8.61094 176.107 9.02731 175.867 9.5659C175.63 10.1003 175.512 10.728 175.512 11.4489C175.512 12.1863 175.628 12.8285 175.86 13.3754C176.097 13.9223 176.428 14.3469 176.855 14.6493C177.286 14.9518 177.791 15.103 178.371 15.103C178.752 15.103 179.098 15.0367 179.409 14.9041C179.72 14.7716 179.983 14.581 180.198 14.3324C180.414 14.0838 180.567 13.7855 180.658 13.4375H182.125C182.042 14.001 181.837 14.5085 181.509 14.9601C181.186 15.4075 180.757 15.7638 180.223 16.029C179.693 16.29 179.075 16.4205 178.371 16.4205Z" fill="white"/>
<path d="M185.735 10.4794V16.2216H184.269V3.49435H185.735V8.16764H185.86C186.083 7.67462 186.419 7.28311 186.866 6.9931C187.318 6.69895 187.919 6.55187 188.668 6.55187C189.319 6.55187 189.889 6.68238 190.377 6.94339C190.866 7.20025 191.245 7.59591 191.515 8.13035C191.788 8.66066 191.925 9.33596 191.925 10.1563V16.2216H190.458V10.2557C190.458 9.49754 190.261 8.91131 189.868 8.49701C189.478 8.07856 188.938 7.86934 188.246 7.86934C187.765 7.86934 187.334 7.97085 186.953 8.17385C186.576 8.37686 186.278 8.67308 186.058 9.06253C185.843 9.45197 185.735 9.92427 185.735 10.4794Z" fill="white"/>
<path d="M196.075 10.4794V16.2216H194.608V6.67616H196.025V8.16764H196.149C196.373 7.68291 196.713 7.29347 197.168 6.99932C197.624 6.70102 198.212 6.55187 198.933 6.55187C199.58 6.55187 200.145 6.68445 200.63 6.9496C201.115 7.21061 201.492 7.60834 201.761 8.14278C202.03 8.67308 202.165 9.34425 202.165 10.1563V16.2216H200.698V10.2557C200.698 9.50583 200.503 8.92166 200.114 8.50322C199.725 8.08064 199.19 7.86934 198.511 7.86934C198.043 7.86934 197.624 7.97085 197.255 8.17385C196.891 8.37686 196.603 8.67308 196.392 9.06253C196.18 9.45197 196.075 9.92427 196.075 10.4794Z" fill="white"/>
<path d="M208.723 16.4205C207.861 16.4205 207.105 16.2154 206.454 15.8052C205.808 15.3951 205.303 14.8213 204.938 14.0838C204.578 13.3464 204.397 12.4846 204.397 11.4986C204.397 10.5043 204.578 9.63633 204.938 8.89473C205.303 8.15314 205.808 7.57726 206.454 7.16711C207.105 6.75695 207.861 6.55187 208.723 6.55187C209.584 6.55187 210.338 6.75695 210.985 7.16711C211.635 7.57726 212.141 8.15314 212.501 8.89473C212.866 9.63633 213.048 10.5043 213.048 11.4986C213.048 12.4846 212.866 13.3464 212.501 14.0838C212.141 14.8213 211.635 15.3951 210.985 15.8052C210.338 16.2154 209.584 16.4205 208.723 16.4205ZM208.723 15.103C209.377 15.103 209.916 14.9352 210.338 14.5996C210.761 14.2641 211.074 13.8228 211.277 13.2759C211.48 12.7291 211.581 12.1366 211.581 11.4986C211.581 10.8606 211.48 10.2661 211.277 9.71505C211.074 9.16403 210.761 8.71866 210.338 8.37893C209.916 8.03921 209.377 7.86934 208.723 7.86934C208.068 7.86934 207.529 8.03921 207.107 8.37893C206.684 8.71866 206.371 9.16403 206.168 9.71505C205.965 10.2661 205.864 10.8606 205.864 11.4986C205.864 12.1366 205.965 12.7291 206.168 13.2759C206.371 13.8228 206.684 14.2641 207.107 14.5996C207.529 14.9352 208.068 15.103 208.723 15.103Z" fill="white"/>
<path d="M216.753 3.49435V16.2216H215.287V3.49435H216.753Z" fill="white"/>
<path d="M223.317 16.4205C222.456 16.4205 221.7 16.2154 221.049 15.8052C220.403 15.3951 219.897 14.8213 219.533 14.0838C219.172 13.3464 218.992 12.4846 218.992 11.4986C218.992 10.5043 219.172 9.63633 219.533 8.89473C219.897 8.15314 220.403 7.57726 221.049 7.16711C221.7 6.75695 222.456 6.55187 223.317 6.55187C224.179 6.55187 224.933 6.75695 225.579 7.16711C226.23 7.57726 226.735 8.15314 227.096 8.89473C227.46 9.63633 227.643 10.5043 227.643 11.4986C227.643 12.4846 227.46 13.3464 227.096 14.0838C226.735 14.8213 226.23 15.3951 225.579 15.8052C224.933 16.2154 224.179 16.4205 223.317 16.4205ZM223.317 15.103C223.972 15.103 224.511 14.9352 224.933 14.5996C225.356 14.2641 225.669 13.8228 225.872 13.2759C226.075 12.7291 226.176 12.1366 226.176 11.4986C226.176 10.8606 226.075 10.2661 225.872 9.71505C225.669 9.16403 225.356 8.71866 224.933 8.37893C224.511 8.03921 223.972 7.86934 223.317 7.86934C222.663 7.86934 222.124 8.03921 221.702 8.37893C221.279 8.71866 220.966 9.16403 220.763 9.71505C220.56 10.2661 220.459 10.8606 220.459 11.4986C220.459 12.1366 220.56 12.7291 220.763 13.2759C220.966 13.8228 221.279 14.2641 221.702 14.5996C222.124 14.9352 222.663 15.103 223.317 15.103Z" fill="white"/>
<path d="M233.734 20C233.026 20 232.417 19.9089 231.907 19.7266C231.398 19.5484 230.973 19.3123 230.633 19.0181C230.298 18.7281 230.031 18.4174 229.832 18.086L231 17.2656C231.133 17.4397 231.3 17.6385 231.503 17.8622C231.706 18.0901 231.984 18.2869 232.336 18.4526C232.692 18.6225 233.159 18.7074 233.734 18.7074C234.505 18.7074 235.141 18.521 235.642 18.1481C236.144 17.7752 236.394 17.1911 236.394 16.3956V14.4567H236.27C236.162 14.6307 236.009 14.8461 235.81 15.103C235.615 15.3557 235.334 15.5815 234.965 15.7804C234.6 15.9751 234.107 16.0725 233.486 16.0725C232.715 16.0725 232.023 15.8902 231.41 15.5256C230.801 15.161 230.318 14.6307 229.962 13.9347C229.61 13.2387 229.434 12.3935 229.434 11.3992C229.434 10.4214 229.606 9.57004 229.95 8.84502C230.294 8.11585 230.772 7.5524 231.385 7.15468C231.998 6.75281 232.707 6.55187 233.511 6.55187C234.132 6.55187 234.625 6.65545 234.99 6.8626C235.358 7.0656 235.64 7.29761 235.835 7.55862C236.034 7.81549 236.187 8.02678 236.295 8.1925H236.444V6.67616H237.861V16.4951C237.861 17.3154 237.674 17.9824 237.302 18.4961C236.933 19.014 236.436 19.3931 235.81 19.6334C235.189 19.8778 234.497 20 233.734 20ZM233.685 14.755C234.273 14.755 234.77 14.6203 235.176 14.3511C235.582 14.0818 235.891 13.6944 236.102 13.1889C236.313 12.6835 236.419 12.0786 236.419 11.3743C236.419 10.6866 236.315 10.0796 236.108 9.55347C235.901 9.02731 235.595 8.61508 235.189 8.31679C234.783 8.01849 234.281 7.86934 233.685 7.86934C233.063 7.86934 232.545 8.02678 232.131 8.34164C231.721 8.65651 231.412 9.0791 231.205 9.6094C231.002 10.1397 230.901 10.728 230.901 11.3743C230.901 12.0372 231.004 12.6234 231.211 13.133C231.423 13.6385 231.733 14.0362 232.143 14.3262C232.558 14.6121 233.072 14.755 233.685 14.755Z" fill="white"/>
<path d="M240.545 16.2216V6.67616H242.012V16.2216H240.545ZM241.291 5.08525C241.005 5.08525 240.759 4.98789 240.552 4.79317C240.349 4.59845 240.247 4.36437 240.247 4.09094C240.247 3.8175 240.349 3.58342 240.552 3.3887C240.759 3.19398 241.005 3.09662 241.291 3.09662C241.577 3.09662 241.822 3.19398 242.025 3.3887C242.232 3.58342 242.335 3.8175 242.335 4.09094C242.335 4.36437 242.232 4.59845 242.025 4.79317C241.822 4.98789 241.577 5.08525 241.291 5.08525Z" fill="white"/>
<path d="M248.7 16.4205C247.781 16.4205 246.987 16.2175 246.32 15.8115C245.657 15.4013 245.146 14.8296 244.785 14.0963C244.429 13.3588 244.251 12.5012 244.251 11.5235C244.251 10.5457 244.429 9.68397 244.785 8.93824C245.146 8.18835 245.647 7.60419 246.289 7.18575C246.936 6.76317 247.69 6.55187 248.551 6.55187C249.048 6.55187 249.539 6.63473 250.024 6.80045C250.509 6.96617 250.95 7.23547 251.348 7.60834C251.746 7.97706 252.062 8.46593 252.299 9.07495C252.535 9.68397 252.653 10.4339 252.653 11.3246V11.946H245.295V10.6783H251.161C251.161 10.1397 251.054 9.65912 250.838 9.23653C250.627 8.81395 250.324 8.48043 249.931 8.236C249.541 7.99156 249.082 7.86934 248.551 7.86934C247.967 7.86934 247.462 8.01435 247.035 8.30436C246.612 8.59022 246.287 8.96309 246.059 9.42297C245.831 9.88284 245.717 10.3759 245.717 10.902V11.7472C245.717 12.4681 245.842 13.0792 246.09 13.5805C246.343 14.0776 246.693 14.4567 247.141 14.7177C247.588 14.9746 248.108 15.103 248.7 15.103C249.086 15.103 249.434 15.0491 249.744 14.9414C250.059 14.8296 250.331 14.6638 250.559 14.4443C250.786 14.2205 250.963 13.943 251.087 13.6115L252.504 14.0093C252.355 14.4898 252.104 14.9124 251.752 15.277C251.4 15.6375 250.965 15.9192 250.447 16.1222C249.929 16.321 249.347 16.4205 248.7 16.4205Z" fill="white"/>
<path d="M261.645 8.81395L260.328 9.18682C260.245 8.96724 260.123 8.75387 259.961 8.54672C259.804 8.33543 259.588 8.16142 259.315 8.02471C259.041 7.88799 258.691 7.81963 258.265 7.81963C257.68 7.81963 257.194 7.95428 256.804 8.22357C256.419 8.48872 256.226 8.82638 256.226 9.23653C256.226 9.60111 256.359 9.88905 256.624 10.1003C256.889 10.3116 257.303 10.4877 257.867 10.6286L259.284 10.9766C260.137 11.1837 260.773 11.5007 261.192 11.9274C261.61 12.35 261.819 12.8948 261.819 13.5618C261.819 14.1087 261.662 14.5976 261.347 15.0284C261.036 15.4593 260.601 15.799 260.042 16.0476C259.483 16.2962 258.832 16.4205 258.091 16.4205C257.117 16.4205 256.311 16.2092 255.673 15.7866C255.035 15.364 254.631 14.7467 254.461 13.9347L255.853 13.5867C255.986 14.1004 256.237 14.4857 256.605 14.7426C256.978 14.9994 257.465 15.1279 258.066 15.1279C258.749 15.1279 259.292 14.9829 259.694 14.6929C260.1 14.3987 260.303 14.0465 260.303 13.6364C260.303 13.3049 260.187 13.0274 259.955 12.8036C259.723 12.5758 259.367 12.4059 258.886 12.2941L257.295 11.9212C256.421 11.714 255.779 11.393 255.369 10.9579C254.963 10.5188 254.76 9.96984 254.76 9.31111C254.76 8.77252 254.911 8.29607 255.213 7.88177C255.52 7.46747 255.936 7.14225 256.462 6.9061C256.993 6.66995 257.593 6.55187 258.265 6.55187C259.209 6.55187 259.951 6.75902 260.489 7.17332C261.032 7.58762 261.417 8.13449 261.645 8.81395Z" fill="white"/>
</svg>
</template>