<template>
  <div class="mobile-menu">
    <div 
      class="mobile-menu__close" 
      @click="closeModal"
    >
      <icon-close/>
    </div>
    <div 
      class="mobile-menu-item" 
      v-for="item in navList" 
      :key="item.key"
      @click="scrollToSlide(item.key)"
    >{{ item.text }}</div>
    <div 
      class="mobile-menu__contact-us" 
      @click="scrollToSlide('contact')"
    >Связаться с нами</div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose'
export default {
  components: {
    IconClose,
  },
  props: {
    navList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
      this.closeModal()
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";
@import "../assets/scss/animations.scss";

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1C3BBD;
  box-shadow: -202px 0px 81px rgba(38, 45, 52, 0.01), -113px 0px 68px rgba(38, 45, 52, 0.05), -50px 0px 50px rgba(38, 45, 52, 0.09), -13px 0px 28px rgba(38, 45, 52, 0.1), 0px 0px 0px rgba(38, 45, 52, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  min-width: 332px;
  animation: showMobileMenu .5s;
  z-index: 2;

  @include breakpoint(mobile) {
    min-width: 85%;
  }

  @include breakpoint(mobileonly) {
    min-width: 90%;
  }

  &__close {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  &-item {
    color: #fff;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  &__contact-us {
    margin-top: 24px;
    width: fit-content;
    @include button-white-bordered;
  }
}
</style>
